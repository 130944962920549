.passwordField
  position: relative
  .icon
    &::before
      content: ""
      display: inline-block
      width: 20px
      height: 20px
      background: center/contain no-repeat
    &-eye
      cursor: pointer
      top: 12px
      right: 35px
      position: absolute
      &::before
        background-image: url("/icons/eyes/eye.svg")
      &-slash
        cursor: pointer
        top: 12px
        right: 35px
        position: absolute
        &::before
          background-image: url("/icons/eyes/eye-slash.svg")

