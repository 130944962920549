.task_btn {
	padding: 8px 20px;
	border-right: none;
	background-color: #fff;
	transition: background-color 0.2s ease;
}

.task_btn:hover {
	background-color: #ebedf3;
}

.task_btn:active {
	background-color: rgb(85, 124, 175);
}

.task_btn.active {
	color: #333;
	background-color: rgb(85, 124, 175);
}
.editComment {
	display: flex;
	& p {
		text-align: left;
		color: gray;
		margin-right: 10px;
		cursor: pointer;
	}
	& p:hover {
		text-decoration: underline;
	}
}
