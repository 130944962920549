//cashier list
.cashier-list-dd {
    padding: 5px 20px;
    box-sizing: border-box;
    border: 1px solid #B8543E;
    border-radius: 10px;
    color: #FFF;
    background: #B8543E;
}

.cashier-list-btn {
    padding: 5px 20px !important;
    margin-top: 0 !important;
}

.cashier-list-header-btn {
    margin-left: 20px !important;
}

.cashier-pagination-box {
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding-left: 0;
    align-items: center;
    flex-wrap: wrap;
}

.cashier-pagination-mainlink {
    padding: 10px 30px;
    display: block;
    border-radius: 10px;
    background: #C4C4C4;
    color: black;
    margin: auto 10px;
    margin-top: 20px;

    &:hover {
        color: black;
        background: #A3898C;
    }
}

.cashier-pagination-activelink {
    background: #B8543E;
    color: white;

    &:hover {
        background: #a83920;
        color: white;
    }
}

.cashier-pagination-prevlink {
    margin: 20px 10px auto 10px;
}

.cashier-pagination-nextlink {
    margin: 20px 10px auto 10px;
}

.cashier-pagination-breaklink {
    padding: 15px;
}

.cashier-table-main-wrapper {
    overflow-x: scroll;
}

//cashier create form
.cashier-create-org-select-box {
    min-height: 300px;
}

.cashier-create-org-list-box {
    position: relative;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 0 0 20px 20px;
    border: 1px solid #C4C4C4;
    max-height: 200px;
    overflow-y: auto;
}

.cashier-create-org-list-item {
    background: #FFF;
    padding: 5px 0;

    &:hover {
        background: #C4C4C4;
    }
}

.cashier-btn-create {
    background: #B8543E;
    color: #FFF;
    padding: 5px 50px;
    border-radius: 10px;
    border: 1px solid #B8543E;
    margin-top: 20px;

    &:hover {
        background: #c5563d;
        border: 1px solid #c5563d;
    }

    &:disabled {
        background: #A3898C !important;
        border: 1px solid #A3898C !important;
    }

    &:active {
        background: #a83920;
        border: 1px solid #a83920;
    }
}

.cashier-create-close-btn {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 0;
    padding-right: 10px;
    padding-left: 10px;
}

.cashier-head-create {
    background: #C4C4C4;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cashier-main-create {
    box-sizing: border-box;
    padding: 20px;
}

.cashier-input-create {
    border-radius: 0 !important;
    background-color: #C4C4C4 !important;
    min-height: 25px !important;

    &::placeholder {
        color: black !important;
    }
}

.cashier-create-column {
    width: 100%;
}

.cashier-list-title {
    color: #FFF;
    font-weight: normal;
}

.flex-wrap {
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: column;
}

.space-betwwen {
    justify-content: space-between;
    flex-wrap: wrap;
}

.b-r-15-px {
    border-radius: 15px !important;
}

.m-b-100-px {
    margin-bottom: 100px;
}

.m-b-300-px {
    margin-bottom: 300px;
}

.m-b-20-px {
    margin-bottom: 20px;
}

.m-b-40-px {
    margin-bottom: 40px;
}

.m-10-px {
    margin: 10px;
}

.shift-height-50vh {
    height: 50vh;
    overflow-y: scroll;
}

@media screen and (min-width:760px) {
    .cashier-create-column {
        width: 50%;
    }

    .flex-wrap {
        flex-direction: row;
        justify-content: space-between;
    }
}

@media screen and (min-width:900px) {
    .cashier-table-main-wrapper {
        overflow-x: auto;
    }

    .cashier-pagination-prevlink {
        margin: 20px 10px auto 0;
    }

    .cashier-pagination-nextlink {
        margin: 20px 0 auto 10px;
    }
}