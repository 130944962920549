

.pagination {
  font-size: 14px;
  font-family: inherit;
  max-width: 400px;
  display: flex;
  margin-right: 10px;
  margin-left: 10px;
  list-style: none;
  cursor: pointer;
}

.pagination a {
  padding: 5px 10px;
  border-radius: 5px;
  // border: 1px solid #6c7ac9;
  color: #5E6278;
}

.pagination__link {
  margin: 0 10px;
  font-weight: bold;
}

.pagination__link--active  {
  color: #fff;
  background: #009EF7;
  
}
.pagination__link--active a {
  color: #fff;
  background: #009EF7;

}

.pagination__link--disabled a {
  color: rgb(198, 197, 202);
  // border: 1px solid rgb(198, 197, 202);
}
