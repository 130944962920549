.link_icon {
  color: #7E8299;
  background-color: #F3F6F9;
  border-color: #F3F6F9;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  border-radius: 4px;
  font-size: 1rem !important;
  height: calc(1em + 1rem + 1px);
  width: calc(1em + 1rem + 1px);
  &:hover {
    color: #FFFFFF;
    background-color: #6993FF;
    border-color: #6993FF;
  }
}