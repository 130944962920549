.loaderodf-box {
    position: absolute;
    top: 0%;
    left: 0%;
    height: 100%;
    width: 100%;
    background: #524e4e78;
    z-index: 999;
    display: flex;
    justify-content: center;
}