.organization-create-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.organization-list-title {
    color: #c4c4c4;
    font-weight: normal;
    margin-bottom: 40px;
}

.oraganization-input-create {
    background-color: #c4c4c4 !important;
    border-radius: 15px !important;
    // border: 1px solid #c4c4c4 !important;

    &::placeholder {
        color: black !important;
    }
}

.organization-confirmation-pass {
    margin-top: 20px;
}

.min-h-220 {
    min-height: 220px;
}

.m-l-10px {
    margin-left: 10px;
}

.organization-modal-box {
    box-sizing: border-box;
    padding: 20px;
    min-height: 100px;
}

.organization-cursor-default {
    cursor: default !important;
}

.organization-info-box {
    min-height: 510px !important;
}

.organization-info-content {
    height: 450px !important;
}

.organization-w-100 {
    width: 100%;
}

.organization-permissions-list {
    height: 20vh;
    overflow-y: scroll;
    overflow-x: auto;
}

@media screen and (min-width:760px) {
    .organization-create-wrapper {
        flex-direction: row;
    }

    .organization-info-content {
        height: 340px !important;
    }

    .organization-info-box {
        min-height: 400px !important;
    }
}