.datePickerCustomStyles{
    margin-left: 10px;
    & .react-datepicker-wrapper{
        width: auto !important;
        & .react-datepicker__input-container{
            & > input{
                padding-left: 15px;
                width: 185px;
                border: 1px solid rgb(148, 148, 148,0.5);
                border-radius: 4px;
                height: 52px;
            }
        }
    }
}

.datePickerCustomStyles.small{
    & .react-datepicker-wrapper{
        width: 100% !important;
        & .react-datepicker__input-container{
            & > input{
                height: 32px;
                width: 100%;
            }
        }
    }
}