.timePicker{
// background-color: lightcoral;



}

.timePicker input[type=time]{
  // background-color: lightcoral;
  background-color: #EFF2F5;
  border-radius: 4px;
  // width: 70px;
  margin-left: 10px;
  height: 25px;
  border: none;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 4px;
} 
