.resizer {
    display: inline-block;
    position: absolute;
    width: 20px;
    top: 0;
    bottom: 0;
    right: 0;
    cursor: col-resize;
    z-index: 10;
}

.cashier-table-wrapper {
    height: 52vh;
    overflow-y: auto;
    overflow-x: scroll;
}

.cashier-table {
    width: 100%;
}

.cashier-th-sort {
    cursor: pointer;
    padding: 20px 0;
    display: block;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
}

.cashier-th-sort-asc {
    border-top: 1px solid #B8543E;
}

.cashier-th-sort-desc {
    border-bottom: 1px solid #B8543E;
}

.cashier-table-td {
    text-align: center;
    padding: 10px;
    border-right: 1px solid #C4C4C4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.cashier-th-filter-input {
    box-sizing: border-box;
    padding: 10px;
    border: 0;
    margin-top: 20px;
    width: 100%;
    border-top: 2px solid #000;
    box-shadow: inset 0 4px 0 #f7ecec;
    min-height: 42px;
    z-index: 1;
    position: relative;
}

.cashier-table-th {
    border-bottom: 2px solid #000;
    box-shadow: 0 4px 0 #f7ecec;
    padding: 20px 0 0 0 !important;
    justify-content: space-between !important;
}

.cashier-table-td-right {
    border-right: 0 !important;
}

.cashier-table-td-hover {
    background: #FFF;

    &:hover {
        background: #c4c4c4;
    }
}

@media screen and (min-width:900px) {
    .cashier-table-wrapper {
        overflow-x: auto;
    }
}