@font-face {
  font-family: 'TTNorms';
  src: local('TTNorms'), url('../../../ts/components/assets/fonts/TTNorms-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'TTNorms';
  src: local('TTNorms'), url('../../../ts/components/assets/fonts/TTNorms-Light.otf') format('opentype');
  font-weight: 300;
}

@font-face {
  font-family: 'TTNorms';
  src: local('TTNorms'), url('../../../ts/components/assets/fonts/TTNorms-Regular.otf') format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'TTNorms';
  src: local('TTNorms'), url('../../../ts/components/assets/fonts/TTNorms-Medium.otf') format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'TTNorms';
  src: local('TTNorms'), url('../../../ts/components/assets/fonts/TTNorms-Bold.otf') format('opentype');
  font-weight: 700;
}

@font-face {
  font-family: 'TTNorms';
  src: local('TTNorms'), url('../../../ts/components/assets/fonts/TTNorms-ExtraBold.otf') format('opentype');
  font-weight: 800;
}

@font-face {
  font-family: 'TTNorms';
  src: local('TTNorms'), url('../../../ts/components/assets/fonts/TTNorms-Black.otf') format('opentype');
  font-weight: 900;
}